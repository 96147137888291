<template>
  <div>

    <b-modal
      id="modal-expense-category"
      size="xl"
      title="Pilih Kategori Pengeluaran"
      hide-footer
    >
      <b-tabs>
        <b-tab
          title="Daftar Kategori Pengeluaran"
          active
        >
          <div class="mt-7">
            <Table
              purpose="modal"
              @chooseCategory="chosenCategory"
            />
          </div>
        </b-tab>
        <b-tab title="Tambah Baru">
          <div class="mt-3">
            <Form
              purpose="modal"
              :form="form"
              editTarget=""
              route="expense-categories"
              @formOnSubmit="submitedCategory"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>

import Form from '@/component/expense-categories/Form.vue'
import Table from '@/component/expense-categories/Table.vue'

export default {

  components: {
    Table,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: '',
        description: ''
      }
    }
  },

  methods: {

    chosenCategory(value) {
      this.$emit('chosenCategory', value)
    },

    submitedCategory(value) {
      this.$emit('submitedCategory', value)
    }

  }

}
</script>

<style>
</style>