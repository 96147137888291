<template>
  <div class="row">
    <div class="col-md-4">
      <div
        class="d-flex justify-content-center align-items-center image-container"
      >
        <div style="height: 350px; width: 350px; position: relative">
          <img
            class="image"
            :src="[uploadedImageUrl == '' ? form.photo_name : uploadedImageUrl]"
            @load="onImageLoad()"
          />
        </div>
      </div>
    </div>

    <div class="col-md">
      <b-form @submit.stop.prevent="formOnSubmit" enctype="multipart/form-data">
        <!-- Input Flow Type -->
        <b-form-group
          id="input-group-flow-type"
          label="Jenis Keuangan:"
          label-for="input-flow-type"
        >
          <b-form-select
            id="input-flow-type"
            v-model="form.flow_type"
            :options="flowTypes"
          ></b-form-select>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-expense-category">
          <label for="input-trigger-modal-expense-category"
            >Kategori Pengeluaran: <em class="text-muted">opsional</em></label
          >
          <b-form-input
            id="input-trigger-modal-expense-category"
            v-model="display.expense_category_name"
            placeholder="Pilih Kategori"
            :readonly="
              form.flow_type == 2 ? false : form.flow_type == 1 ? true : true
            "
            :class="
              form.flow_type == 2 ? 'disabled' : form.flow_type == 1 ? '' : ''
            "
            :disabled="
              form.flow_type == 2 ? true : form.flow_type == 1 ? false : false
            "
            @click="$bvModal.show('modal-expense-category')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.expense_category_id }}</small>
        </b-form-group>

        <!-- Input Date -->
        <!-- <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <b-form-datepicker
            v-model="form.date"
            id="input-date"
            placeholder="Tanggal"
            locale="id"
          ></b-form-datepicker>
          <small class="text-danger">{{ error.date }}</small>
        </b-form-group> -->

        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-form-datepicker
                locale="id"
                placeholder="Tanggal"
                id="input-date"
                v-model="form.date"
                button-only
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-prepend>
            <b-form-input
              id="input-date"
              v-model="form.date"
              type="text"
              placeholder="YYYY-MM-DD"
              @input="formaterDate"
            ></b-form-input>
          </b-input-group>
          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Keterangan:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Keterangan"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Amount -->
        <b-form-group
          id="input-group-amount"
          label="Jumlah:"
          label-for="input-amount"
          v-if="type == 'add'"
        >
          <b-form-input
            id="input-amount"
            v-model="formatedAmount"
            inputmode="numeric"
            placeholder="Jumlah"
          ></b-form-input>
          <small class="text-danger">{{ error.amount }}</small>
        </b-form-group>

        <!-- Input payment type -->
        <b-form-group
          id="input-group-flow-type"
          label="Metode Pengeluaran:"
          label-for="input-flow-type"
        >
          <b-form-select
            id="input-flow-type"
            v-model="form.expense_method_type"
            :options="selectMethod"
            @change="setMethod"
          ></b-form-select>
        </b-form-group>

        <!-- Input payment type transfer -->
        <b-form-group
          id="input-group-flow-type"
          label="Rekening:"
          label-for="input-flow-type"
          v-if="isTransfer"
        >
          <b-form-select
            id="input-flow-type"
            v-model="form.expense_method"
            :options="selectTransfer"
          ></b-form-select>
        </b-form-group>

        <!-- Input Note -->
        <b-form-group id="input-group-notes">
          <label for="input-notes"
            >Catatan: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            placeholder="Catatan"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Image -->
        <b-form-group id="input-group-image">
          <label for="input-image"
            >Gambar: <em class="text-muted">opsional</em></label
          >
          <b-form-file
            v-model="form.photo"
            placeholder="Upload Gambar"
            drop-placeholder="Drop file here..."
            @change="onFileUpload"
          ></b-form-file>
          <small class="text-danger">{{ error.photo }}</small>
        </b-form-group>

        <!-- Button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$router.push('/expense/list')"
        >
          Batal
        </b-button>
      </b-form>
    </div>

    <ModalExpenseCategory
      @chosenCategory="setCategory"
      @submitedCategory="setCategory"
    />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalExpenseCategory from "@/component/general/ModalExpenseCategory.vue";

export default {
  props: {
    form: Object,
    route: String,
    type: String,
    
  },

  components: {
    ModalExpenseCategory,
  },

  data() {
    return {
      // Display Data
      display: {
        expense_category_name: "",
      },
      // Error
      error: {
        photo: "",
        date: "",
        name: "",
        amount: "",
        expense_category_id: "",
        notes: "",
      },
      // Options
      flowTypes: [
        { text: "Pengeluaran", value: 1 },
        { text: "Deposit", value: 2 },
      ],
      selectMethod: [
        { text: "Cash", value: 1 },
        { text: "Transfer", value: 2 },
      ],
      // payment method
      selectTransfer: [],
      isTransfer: false,
      // Others
      imgLoaded: false,
      uploadedImageUrl: "",
    };
  },

  computed: {
    formatedAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.amount = newValue.toLocaleString("id-ID");
        } else {
          this.form.amount = "";
        }
      },
      get() {
        return this.form.amount;
      },
    },
  },

  methods: {
     formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },

    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.photo = evt.target.files[0];
    },

    async setMethod() {
      if (this.form.expense_method_type == 2) {
        // this.form.expense_method = 1
        this.isTransfer = true;
      } else {
        this.form.expense_method = 0;
        this.isTransfer = false;
      }
    },

    async getMethod() {
      let response = await module.setSelectOption("bank-accounts");
      this.selectTransfer = response.data;
      // console.log("==================== res ===============",response);
    },

    async formOnSubmit() {
      if (this.isTransfer == false) {
        this.form.expense_method = 0;
      }
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/expense/list");
      }
    },

    setCategory(value) {
      this.form.expense_category_id = value.id;
      this.display.expense_category_name = value.name;
      this.$bvModal.hide("modal-expense-category");
    },
  },

  watch: {
    "form.expense_category_name": function (newVal, oldVal) {
      this.display.expense_category_name = newVal;
    },
  },

  mounted() {
    this.getMethod();
  },
};
</script>

<style>
</style>